import React from "react";
import {
  FacilityContent,
  FacilityHeader,
  FacilityInfoDetail,
  FacilityStack,
  OfficeInfoContainer,
} from "../../../styles/officeInformation";
import {
  facilityHead,
  facilityInfoDetail,
  locationHead,
} from "../../../constants/officeInformation";
import tick from "../../../assets/svg/check.svg";
import Address from "./address";

const OfficeInformation = () => {
  return (
    <OfficeInfoContainer>
      <FacilityHeader>{facilityHead}</FacilityHeader>
     <FacilityStack>
     {facilityInfoDetail?.map((info, index) => (
        <FacilityContent>
          <img src={tick} />
          <FacilityInfoDetail>
            {info}
          </FacilityInfoDetail>
        </FacilityContent>
      ))}
     </FacilityStack>
            <FacilityHeader>{locationHead}</FacilityHeader>
            <Address />
    </OfficeInfoContainer>
  );
};

export default OfficeInformation;
