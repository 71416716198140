import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';

export const officeTitle = "Shared Office at Guindy";

export const infoDetail = [
    {
        icon : <EmailIcon sx={{
            color : "#FFF"
        }} />,
        text : "chennaisharedoffice@gmail.com"
    },
    {
        icon : <PhoneIcon sx={{
            color : "#FFF"
        }} />,
        text : "+91 99400 64728"
    }
]