
export const contact = { 
    LOCATION:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d432.7417384455793!2d80.2093946192229!3d12.996730874256457!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5267add12a21cf%3A0x33a6279b991060bb!2sSoft%20Tech%20Ashram!5e0!3m2!1sen!2sin!4v1711087899391!5m2!1sen!2sin",
  };

export const addressDetail = {
    address : "Address",
    detail :     "15, Gopalakrishnan Street, Secretariat Colony, Adambakkam, Chennai - 600032"

}
