import React from 'react'
import { MainContainer } from '../../../styles/home'
import Office from '../Office/Office'
import OfficeInformation from '../officeInformation'

const Home = () => {
  return (
    <MainContainer>
      <Office />
      <OfficeInformation />
    </MainContainer>
  )
}

export default Home