import { Box, styled } from "@mui/material";

export const OfficeContainer = styled(Box)(({theme})=>({
    width : "55%",
    height : "100vh",
    display : "flex",
    flexDirection : "column",
    gap  :"20px",
    backgroundColor : "#2d89f9",
    padding : "20px",
    boxSizing : "border-box",
    [theme.breakpoints.down("1000")]:{
       width : "100%",
    },
    [theme.breakpoints.down("sm")]:{
        height : "auto"
    }
}))


export const OfficeTitle = styled(Box)(({theme})=>({
    display : "flex",
    justifyContent : "center",
    color : "#FFF",
    fontSize : "20px"

}))


export const InfoWrapper = styled(Box)(({theme})=>({
    display : "flex",
    flexDirection : "column",
    gap : "10px"
}))


export const InfoItemWrapper = styled(Box)(({theme})=>({
    display : "flex",
    gap : "10px"
}))

export const InfoItem = styled(Box)(({theme})=>({
    fontSize : "18px",
    color  : "#FFF"
}))