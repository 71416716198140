import { Box, Grid, MobileStepper, styled } from "@mui/material";

export const TestimonialCarouselContainer = styled(Box)(({theme})=>({
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  }));

  export const TestimonialMobileStepper = styled(MobileStepper)({
    display: "flex",
    justifyContent: "center",
    // backgroundColor: "#fff",
    backgroundColor : "transparent",
    marginTop : '10px',
  });


  export const TestimonialsConatiner = styled(Grid)(({theme})=>({
    // padding: "20px",
    // margin: "20px",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
      margin: "0px",
    },
  }))


  export const ImageWrapper = styled("img")(({theme})=>({
    width : "100%",
    height : "65vh",
    [theme.breakpoints.down("1000")]:{
      width : "100%",
   },
   [theme.breakpoints.down("sm")]:{
    height : "300px"
   }
  }))