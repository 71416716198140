import React from 'react'
import { InfoItem, InfoItemWrapper, InfoWrapper, OfficeContainer, OfficeTitle } from '../../../styles/office'
import { infoDetail, officeTitle } from '../../../constants/office'
import ImageSwipe from './ImageSwipe'

const Office = () => {
  return (
   <OfficeContainer>
    <OfficeTitle>
    {officeTitle}
    </OfficeTitle>
    <ImageSwipe />
    <InfoWrapper>
      {infoDetail?.map((info,index)=><InfoItemWrapper key={index}>
       {info?.icon}
       <InfoItem>{info?.text}</InfoItem>
      </InfoItemWrapper>)}
    </InfoWrapper>
   </OfficeContainer>
  )
}

export default Office