import { Box, styled } from "@mui/material";


export const OfficeInfoContainer = styled(Box)(({theme})=>({
    width : "45%",
    height : "100vh",
    display : "flex",
    flexDirection : "column",
    gap  :"20px",
    padding : "20px",
    boxSizing : "border-box",
    [theme.breakpoints.down("1000")]:{
        width : "100%",
     },
     [theme.breakpoints.down("sm")]:{
        height : "auto"
    }
}))


export const FacilityHeader = styled(Box)(({theme})=>({
    color : "#d74043",
    fontSize : "20px",
    marginTop : "10px"
}));

export const FacilityStack = styled(Box)(({theme})=>({
    display : "flex",
    flexDirection : "column",
    gap : "5px"
}))

export const FacilityContent = styled(Box)(({theme})=>({
    display : "flex",
    gap : "10px",
    paddingLeft : "40px",
    marginTop : "10px",
    [theme.breakpoints.down("sm")]:{
        paddingLeft : "0px",
    }
}))

export const FacilityInfoDetail = styled(Box)(({theme})=>({
    fontSize : "19px",
}))