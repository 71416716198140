import './App.css';
import Root from './components/Pages/Root';

function App() {
  return (
  <Root />   
  );
}

export default App;
