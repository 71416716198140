import { styled, Skeleton, Grid, Typography, Box } from "@mui/material";

export const Iframe = styled("iframe")(({ theme }) => ({
  width: "100%",
  height: "150px",
  border: 0,
}));

export const CustomSkeleton = styled(Skeleton)({
  width: "100%",
  height: "300px",
  border: 0,
});

export const PrimaryText = styled(Typography)(({ theme }) => ({
  textAlign: "left",
  fontSize: "16px",
  fontStyle: "normal",
  fontVariant: "normal",
  fontFamily : theme.font.content,
  fontWeight: 400,
  letterSpacing: 0,
  [theme.breakpoints.up("sm")]: {
    fontSize: "16px",
  },
}));

export const InfoGrid = styled(Grid)({
  display: "flex",
});

export const AddressBox = styled(Box)(({theme})=>({
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  width : "100%",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: "10px",
  },
}))

export const ContactGrid = styled(Grid)(({ theme }) => ({
  marginTop: "20px",
  [theme.breakpoints.up("340")]: {
    marginTop: 0,
  },
}));

  // new
  export const AddressWrapper = styled(Box)(({theme})=>({
    display : "flex",
    gap : "10px",
    [theme.breakpoints.down("sm")]: {
      flexDirection : "column",
    },
  }))

  export const AddressHead = styled(Box)(({theme})=>({
   fontSize : "20px",
  }))
  
  export const AddressStack = styled(Box)(({theme})=>({
    display : "flex",
    flexDirection : "column",
    gap : "10px",
  }))