import image1 from "../assets/IMG_1.jpg";
import image2 from "../assets/IMG_2.jpg";
import image3 from "../assets/IMG_3.jpg";
import image4 from "../assets/IMG_4.jpg";
import image5 from "../assets/IMG_5.jpg";
import image6 from "../assets/IMG_6.jpg";
import image7 from "../assets/IMG_7.jpg";

export const officeImages = [
  {
    img: image1,
  },
  {
    img: image2,
  },
  {
    img: image3,
  },
  {
    img: image4,
  },
  {
    img: image5,
  },
  {
    img: image6,
  },
  {
    img: image7,
  },
 
];
