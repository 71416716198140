import { Box, useTheme } from "@mui/material";
import React, { useState } from "react";
import {
  AddressHead,
  AddressStack,
  AddressWrapper,
  CustomSkeleton,
  Iframe,
} from "../../../styles/address";
import { addressDetail, contact } from "../../../constants/address";

const Address = () => {
  const theming = useTheme();
  const [isLoading, setLoading] = useState(true);

  return (
    <AddressWrapper>
      <AddressStack>
        <AddressHead>{addressDetail?.address}</AddressHead>
        <Box>{addressDetail?.detail}</Box>
      </AddressStack>
      {isLoading ? (
        <CustomSkeleton
          variant="rounded"
          animation="wave"
          sx={{ visibility: isLoading ? "visible" : "hidden" }}
        />
      ) : (
        <></>
      )}
      <Iframe
        src={contact.LOCATION}
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
        title="address"
        onLoad={() => setLoading(false)}
        style={{
          visibility: isLoading ? "hidden" : "visible",
          height: isLoading && "0px",
        }}
      ></Iframe>
    </AddressWrapper>
  );
};

export default Address;
