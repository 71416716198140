import React, { useState } from "react";
import { autoPlay } from "react-swipeable-views-utils";
import SwipeableViews from "react-swipeable-views";
import { Grid } from "@mui/material";
import {
  ImageWrapper,
  TestimonialCarouselContainer,
  TestimonialMobileStepper,
  TestimonialsConatiner,
} from "../../../styles/imageSwipe";
import { useTheme } from "@emotion/react";
import { officeImages } from "../../../constants/imageSwipe";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const ImageSwipe = () => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = officeImages?.length;
  
  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        data-aos="zoom-in-down"
        data-aos-duration="5000"
      ></Grid>
      <TestimonialsConatiner item xs={12}>
        <TestimonialCarouselContainer>
          <AutoPlaySwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
            data-aos="fade-down"
            data-aos-duration="5000"
            interval={5000}
          >
            {officeImages?.map((office, index) => (
              <ImageWrapper src={office?.img} />
            ))}
          </AutoPlaySwipeableViews>
          <TestimonialMobileStepper
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
          />
        </TestimonialCarouselContainer>
      </TestimonialsConatiner>
    </Grid>
  );
};

export default ImageSwipe;
