export const facilityHead = "Facilities Plugs and Play Office";

export const facilityInfoDetail = [
  "Good individual New Shared A/C office",
  "Individual rooms and Seats",
  "24x7 Security / Surveillance Camera",
  "Attendance System",
  "40+ Seats of Canteen",
  "Lunch & Breakfast OnDemand",
  "Reception Area  & Coffee",
  "High speed internet",
  "Printer / Scanner facility",
  "Spacious Covered 2 Wheeler Parking",
];


export const locationHead = "Our Location";